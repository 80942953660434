import React from "react"
import { Link, graphql } from "gatsby"
import widont from "widont"

import styles from "./headingCta.module.scss"

export const query = graphql`
  fragment HeadingCta on Craft_headingCta_BlockType {
    heading
    body
    links {
      ... on Craft_links_link_BlockType {
        id
        text
        button
        externalLink
        page {
          ... on Craft_EntryInterface {
            title
            uri
            id
          }
        }
      }
    }
  }
`

const HeadingCta = props => {
  if (!props.data.heading && !props.data.body) {
    return null
  }
  return (
    <div className={styles.module}>
      <div className="wrapper">
        <div className="columns no-vertical-gap is-centered">
          <div className="mb-5 column is-four-fifths-tablet is-half-desktop">
            <h2
              className="h-2 mb-3 h--cms-title"
              dangerouslySetInnerHTML={{
                __html: widont(props.data.heading),
              }}
            />
          </div>
        </div>

        {props.data.body && (
          <div className={styles.body}>
            <div className="columns no-vertical-gap is-centered">
              <div
                className="column is-three-fourths-tablet is-half-desktop user-content"
                dangerouslySetInnerHTML={{
                  __html: props.data.body,
                }}
              />
            </div>
          </div>
        )}

        <div className={styles.links}>
          {props.data.links.map(link => {
            if (link.externalLink) {
              return (
                <div key={`${link.id}`} className={styles.link}>
                  <a
                    className={link.button ? `button` : `link`}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={link.externalLink}
                  >
                    {link.text}
                  </a>
                </div>
              )
            } else {
              return (
                <div key={`${link.id}`} className={styles.link}>
                  <Link
                    className={link.button ? `button` : `link`}
                    to={`/${link.page[0].uri}`}
                  >
                    {link.text}
                  </Link>
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default HeadingCta
